import * as React from 'react';
import { Link } from 'gatsby';
import SmallGoldArrow from '../images/small-gold-arrow.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

const CourseComponent = ({ link, title, description, image }) => (
  <>
    <Link to={link}>
      <div className="no-widows h-full flex flex-col group cursor-pointer">
        <GatsbyImage image={image} alt="" className="w-full h-24 object-cover mb-5" />
        <div className="text-xl mb-4">{title}</div>
        {description && <div className="no-widows flex-1 mb-6">{description}</div>}
        <button className="flex items-center">
          <div className="henderson-font text-xs text-yellow-700 font-bold mr-4">WIĘCEJ</div>
          <SmallGoldArrow className={`group-hover:translate-x-2 transition-transform duration-200`} />
        </button>
      </div>
    </Link>
  </>
);

export default CourseComponent;
