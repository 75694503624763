import * as React from 'react';
import DividerComponent from '../components/divider';
import CourseComponent from '../components/course';

const ServicesContainer = ({ services }) => (
  <>
    <div className="text-2xl lg:text-4xl text-blue-600 mb-8">Nauka angielskiego</div>
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-8">
      {services.map((service, i) => (
        <CourseComponent
          key={i}
          link={service.link}
          title={service.title}
          description={service.description}
          image={service.backgroundImage.localFile.childImageSharp.gatsbyImageData}
        />
      ))}
    </div>
    <DividerComponent />
  </>
);

export default ServicesContainer;
