import * as React from 'react';
import DividerComponent from '../components/divider';
import CourseComponent from '../components/course';

const TranslationsContainer = ({ translations }) => (
  <>
    <div className="text-2xl lg:text-4xl text-blue-600 mb-8">Tłumaczenia</div>
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-8">
      {translations.map((translation, i) => (
        <CourseComponent
          key={i}
          link={translation.link}
          description={translation.description}
          image={translation.backgroundImage.localFile.childImageSharp.gatsbyImageData}
        />
      ))}
    </div>
    <DividerComponent />
  </>
);

export default TranslationsContainer;
