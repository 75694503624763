import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Markdown from 'react-markdown';
import LayoutContainer from '../containers/layout';
import PageBackgroundImageComponent from '../components/page-background-image';
import PageContentComponent from '../components/page-content';
import PageTitleComponent from '../components/page-title';
import ServicesContainer from '../containers/services';
import OpinionsContainer from '../containers/opinions';
import TranslationsContainer from '../containers/translations';
import ContactUsContainer from '../containers/contact-us';

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <LayoutContainer title={data.strapiHomepage.title} description={data.strapiHomepage.description}>
      <PageTitleComponent showBackground={true}>
        <div className="text-4xl lg:text-[2.5rem] text-black">{data.strapiHomepage.title}</div>
      </PageTitleComponent>

      <PageBackgroundImageComponent image={data.strapiHomepage.backgroundImage.localFile.childImageSharp.gatsbyImageData} />

      <PageContentComponent showBackground={true}>
        <section className="mb-12">
          <Markdown className="no-widows w-full lg:w-3/5 markdown mb-12">{data.strapiHomepage.description}</Markdown>
          <div className="w-full h-1 bg-gray-200"></div>
        </section>

        <section className="mb-12">
          <ServicesContainer services={data.strapiHomepage.services} />
        </section>

        <section className="mb-12">
          <TranslationsContainer translations={data.strapiHomepage.translations} />
        </section>

        <section className="mb-12">
          <OpinionsContainer opinions={data.strapiHomepage.opinions} />
        </section>

        <section>
          <ContactUsContainer />
        </section>
      </PageContentComponent>
    </LayoutContainer>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      title
      description
      backgroundImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO, quality: 92)
          }
        }
      }
      services {
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO, quality: 92)
            }
          }
        }
        description
        title
        link
      }
      translations {
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO, quality: 92)
            }
          }
        }
        description
        link
      }
      opinions {
        name
        content
      }
    }
  }
`;

export default IndexPage;
