import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import GrayArrow from '../images/gray-arrow.svg';
import GoldArrow from '../images/gold-arrow.svg';

const OpinionsContainer = ({ opinions }) => {
  const [swiper, setSwiper] = React.useState(null);
  const [updateView, setUpdateView] = React.useState(0);

  return (
    <>
      <div className="flex items-center justify-between mb-8">
        <div className="text-2xl lg:text-4xl text-blue-600">Opinie</div>
        <div className="flex items-center">
          {swiper?.isBeginning && <GrayArrow className="cursor-pointer mr-16" />}
          {!swiper?.isBeginning && <GoldArrow onClick={() => swiper.slidePrev()} className="scale-x-[-1] cursor-pointer mr-16" />}
          {!swiper?.isEnd && <GoldArrow onClick={() => swiper.slideNext()} className="cursor-pointer" />}
          {swiper?.isEnd && <GrayArrow className="scale-x-[-1] cursor-pointer" />}
        </div>
      </div>

      <div className="relative mb-20">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          breakpoints={{ 1024: { slidesPerView: 1.5, spaceBetween: 150 } }}
          onSwiper={setSwiper}
          onSlideChange={() => setUpdateView(updateView + 1)}
        >
          {opinions.map((opinion, i) => (
            <SwiperSlide key={i} className="no-widows">
              <div className="no-widows italic text-black mb-4">{opinion.content}</div>
              <div className="flex items-center">
                <div className="w-6 h-0.5 bg-blue-600 mr-2"></div>
                <div className="text-black">{opinion.name}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {!swiper?.isEnd && <div className="hidden lg:block absolute top-0 right-0 z-10 w-1/4 h-full bg-gradient-to-l from-white"></div>}
      </div>

      <div className="w-full h-1 bg-gray-200">
        <div
          className="h-1 bg-yellow-700 transition-all duration-300"
          style={{
            width: `${swiper ? 100 / swiper.slides.length : 0}%`,
            marginLeft: `${swiper ? (swiper.activeIndex / swiper.slides.length) * 100 : 0}%`,
          }}
        ></div>
      </div>
    </>
  );
};

export default OpinionsContainer;
